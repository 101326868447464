<template>
  <el-dialog
    title="数据选择"
    top="60px"
    :modal="false"
    :visible.sync="dataTableVisible"
    width="65%"
    :before-close="handleClose"
  >
    <div style="height: 500px; overflow-y: auto; overflow-x: hidden">
      <!-- <div class="iot-table-title">
        <input type="checkbox" v-model="check" @change="checkboxAll" />
        <ul class="table">
          <li>网关参数</li>
          <li>参数名</li>
          <li>数值类型</li>
          <li>单位</li>
          <li>小数点</li>
          <li>读写</li>
          <li>状态</li>
        </ul>
      </div> -->

      <el-tree
        :data="tableList"
        ref="tree"
        node-key="id"
        default-expand-all
        @node-drag-end="handleDragEnd"
        draggable
        :allow-drop="allowDrop"
        show-checkbox
        @check-change="handleCheckChange"
      >
        <div class="custom-tree-node" slot-scope="{ node }">
          <ul class="table">
            <li>
              <el-cascader
                placeholder="请选择网关参数"
                v-model="node.label.param"
                :props="{ multiple: false }"
                :options="getVariableOptions()"
                :show-all-levels="false"
                size="medium"
                collapse-tags
                @change="paramChanged(node)"
              ></el-cascader>
            </li>
            <!-- <li>
              <input
                type="text"
                v-model="node.label.nameValue"
                placeholder="请输入参数名"
              />
            </li> -->
            <li>
              <select v-model="node.label.typeValue" class="attr">
                <option value="" disabled selected style="display: none">
                  数据类型
                </option>
                <option value="true">数值</option>
                <option value="false">布尔</option>
              </select>
            </li>
            <li v-if="node.label.typeValue == 'true'">
              <input
                type="text"
                placeholder="请输入单位"
                v-model="node.label.company"
              />
            </li>
            <!-- <li v-if="node.label.typeValue == 'true'">
              <el-input-number
                v-model="node.label.decimalPoint"
                size="small"
                :min="0"
                :max="3"
                label="小数点位"
              ></el-input-number>
            </li> -->

            <li>
              <select
                v-model="node.label.reading"
                @change="readingSelect(node)"
                class="attr"
              >
                <option value="" disabled selected style="display: none">
                  是否只读
                </option>
                <option value="true">只读</option>
                <option value="false">读写</option>
              </select>
            </li>
            <li v-if="node.label.typeValue == 'false'">
              <img
                v-if="node.label.off == 'btn-off.png'"
                src="https://oss.cniot.fun/iot-system/sys-program/9-1.png"
                style="height: 40px"
              />
              <img
                v-else-if="node.label.off == 'light-off.png'"
                src="https://oss.cniot.fun/iot-system/sys-program/7-2.png"
                style="height: 40px"
              />
              <img v-else :src="node.label.off" style="height: 40px" />
            </li>
            <li v-if="node.label.typeValue == 'false'">
              <input
                @click="getImg(node, 'off')"
                type="text"
                placeholder="请选择状态1图片"
                v-model="node.label.off"
              />
            </li>
            <li v-if="node.label.typeValue == 'false'">
              <input
                @click="getImg(node, 'on')"
                type="text"
                readonly
                placeholder="请选择状态2图片"
                v-model="node.label.on"
              />
            </li>
            <li>
              <select
                v-if="
                  node.label.reading == 'false' &&
                  node.label.typeValue == 'false'
                "
                v-model="node.label.state"
                class="attr"
              >
                <option value="" disabled selected style="display: none">
                  值状态
                </option>
                <option value="1">设定为on</option>
                <option value="2">设定为off</option>
                <option
                  value="3"
                >
                  交替
                </option>
              </select>
            </li>
          </ul>
        </div>
      </el-tree>

      <hMITableImage
        :key="hMITableImageKey"
        :visible="hMITableImageVisible"
        @putImgs="putImgs"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="del()" size="mini">删除选中</el-button>
      <el-button type="primary" @click="add()" size="mini">添 加</el-button>
      <el-button type="primary" @click="submit()" size="mini">确 定</el-button>
      <el-button @click="dataTableVisible = false" size="mini">取 消</el-button>
    </span>
  </el-dialog>
</template>



<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIPlan from "../util/draw/HMIPlanData.js";
import HMIDraw from "../util/draw/HMIDraw.js";
import hMITableImage from "./HMITableImageSelector.vue";
// 当前组件属性
export default {
  props: ["visible"],
  components: {
    hMITableImage,
  },
  // 数据
  data: function () {
    return {
      check: false,
      checkboxNode: [],
      dataTableVisible: this.visible,
      tableList: [
        {
          id: 1,
          label: {
            param: [],
            nameValue: "",
            btnValue: "",
            typeValue: "",
            company: "",
            // decimalPoint: 0,
            reading: "",
            off: "",
            on: "",
            state: "3",
          },
        },
      ],
      multipleSelection: [],
      hMITableImageKey: 0x11,
      hMITableImageVisible: false,
      dataId: "",
      dataName: "",
    };
  },
  // 创建
  created: function () {
    // 对话框初始化
    this.getTableList();
  },
  // 函数
  methods: {
    handleClose(done) {
      this.$confirm("系统可能不会保存您的数据是否关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    paramChanged(value) {
      let dataValue = value.data.label;
      let data = HMIPlan.getAllData();
      data.forEach((item) => {
        if (item.value == dataValue.param[0]) {
          item.children.forEach((ite) => {
            if (ite.value == dataValue.param[1]) {
              ite.children.forEach((element) => {
                if (element.value == dataValue.param[2]) {
                  dataValue.nameValue = element.label;
                }
              });
            }
          });
        }
      });
    },

    //是否只读改变图片
    readingSelect(node) {
      // console.log(node);
      for (let i = 0; i < this.tableList.length; i++) {
        if (this.tableList[i].id == node.data.id) {
          // console.log(this.tableList[i].label.reading);
          if (this.tableList[i].label.reading == "true") {
            this.tableList[i].label.off = "light-off.png";
            this.tableList[i].label.on = "light-on.png";
          } else if (this.tableList[i].label.reading == "false") {
            this.tableList[i].label.off = "btn-off.png";
            this.tableList[i].label.on = "btn-on.png";
          }
        }
      }
    },

    //返回图片数据到输入框
    putImgs(imgName) {
      console.log(imgName, this.dataId);
      for (let i = 0; i < this.tableList.length; i++) {
        if (this.tableList[i].id == this.dataId) {
          if (this.dataName == "off") {
            this.tableList[i].label.off = imgName;
            //  console.log(this.tableList[i].label.off);
          } else if (this.dataName == "on") {
            this.tableList[i].label.on = imgName;
          }
        }
      }
    },

    getImg(node, name) {
      this.dataId = node.data.id;
      this.dataName = name;
      this.hMITableImageVisible = true;
      this.hMITableImageKey += "0";
    },
    // 获取变量类型
    getVariableOptions: function () {
      // 数据
      // let newAllData = HMIPlan.getAllData();
      // console.log(newAllData);
      return HMIPlan.getAllData();
    },
    dealOptions: function (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children) {
          if (data[i].children.length > 0) {
            this.dealOptions(data[i].children);
          }
        } else {
          if (data[i].paramTypeId) {
            if (this.ctrl.type == 6 || this.ctrl.type == 8) {
              if (data[i].paramTypeId != 2) {
                data.splice(i, 1);
                i--;
              }
            } else if (this.ctrl.type == 9) {
              if (data[i].paramTypeId != 1 && data[i].paramTypeId != 3) {
                data.splice(i, 1);
                i--;
              }
            }
          }
        }
      }
    },
    // dataChanged: function () {
    //   // 内容改变
    //   this.$emit("valuechanged", this.name, this.param);
    // },
    // 是否为变量参数类型
    isVariable: function () {
      // 变量类型
      return this.type === HG.AttrType.variable;
    },

    //是否支持多选参数
    isMultiple: function () {
      return this.ctrl.multiple;
    },
    //添加数据
    add: function () {
      this.tableList.push({
        id:
          this.tableList.length - 1 >= 0
            ? this.tableList[this.tableList.length - 1].id + 1
            : 1,
        label: {
          param: [],
          nameValue: "",
          btnValue: "",
          typeValue: "",
          company: "",
          // decimalPoint: 0,
          reading: "",
          off: "",
          on: "",
          state: "3",
        },
      });
    },
    getTableList: function () {
      // 获取控件
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      let ctrl = null;
      let idx = 0;
      // let selectedSrc = this.getSelectedImage();
      // 检查页面是否有效
      if (page) {
        // 检查是否选中控件
        for (idx = 0; idx < page.ctrls.length; idx++) {
          // 获取选中控件
          if (page.ctrls[idx].selected) {
            // 获取控件
            ctrl = page.ctrls[idx];
            break;
          }
        }

        if (ctrl) {
          this.tableList=[];
          // 遍历key
          console.log(ctrl.dataTable);
          if (ctrl.dataTable.length == 0) {
            this.tableList = [
              {
                id: 1,
                label: {
                  param: [],
                  nameValue: "",
                  btnValue: "",
                  typeValue: "",
                  company: "",
                  // decimalPoint: 0,
                  reading: "",
                  off: "",
                  on: "",
                  state: "1",
                },
              },
            ];
          }
          for (let i = 0; i < ctrl.dataTable.length; i++) {
            //添加数据
            this.tableList.push({
              id:
                this.tableList.length - 1 >= 0
                  ? this.tableList[this.tableList.length - 1].id + 1
                  : 1,
              label: ctrl.dataTable[i],
            });
          }
        }

        // 设置页面的属性
      }
    },
    submit: function () {
      // 设置当前属性的图片
      let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      let ctrl = null;
      let idx = 0;
      // let selectedSrc = this.getSelectedImage();
      // 检查页面是否有效
      if (page) {
        // 检查是否选中控件
        for (idx = 0; idx < page.ctrls.length; idx++) {
          // 获取选中控件
          if (page.ctrls[idx].selected) {
            // 获取控件
            ctrl = page.ctrls[idx];
            break;
          }
        }
        if (ctrl) {
          // 遍历key
          let arr = [];
          this.tableList.forEach((item) => {
            arr.push(item.label);
          });
          //数据绑定在控件
          ctrl.dataTable = arr;
          // this.$emit("paramsDataChanged")
        }

        // 设置页面的属性
      }
      // 页面属性改变
      // this.$emit("paramsDataChanged");
      // 隐藏对话框
      HMIDraw.valueNew=true;
      this.dataTableVisible = false;
    },
    checkboxAll() {
      let arr = [];
      if (this.check) {
        this.tableList.forEach((item) => {
          arr.push(item.id);
        });
        this.checkboxNode = arr;
      } else {
        this.checkboxNode = [];
      }
      this.$refs.tree.setCheckedKeys(this.checkboxNode);
      console.log(this.checkboxNode);
    },
    del() {
      for (let i = 0; i < this.checkboxNode.length; i++) {
        this.tableList = this.tableList.filter((item) => {
          return item.id != this.checkboxNode[i];
        });
      }
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log(this.tableList);
    },
    allowDrop(draggingNode, dropNode, type) {
      if (type != "inner") {
        return true;
      } else {
        return false;
      }
    },
    //节点选中
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      if (checked) {
        this.checkboxNode.push(data.id);
      } else {
        this.checkboxNode = this.checkboxNode.filter((item) => {
          return item != data.id;
        });
      }
    },
  },
};
</script>
<style scoped>
.custom-tree-node {
  width: 100%;
  font-size: 16px;
}
.table {
  width: 98%;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
}
.table li {
  width: 145px;
  flex-shrink: 1;
  text-align: center;
  font-size: 13px;
  color: #000;
  font-weight: 1000;
  padding: 0 10px;
  box-sizing: border-box;
}
.table li option {
  width: 100%;
  height: 40px;
  font-size: 13px;
  text-align: center;
}
.table li select {
  width: 100%;
  height: 40px;
  font-size: 13px;
  text-align: center;
  border: 1px solid rgb(133, 133, 133) !important;
  border-radius: 2px;
  margin: 0 !important;
}
.table li input {
  box-sizing: border-box;
  /* border: none; */
  outline: none;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  font-size: 13px;
  text-align: center;
}

.iot-table-title {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 99;
  background: #fff;
  height: 40px;
}

::v-deep .is-leaf {
  display: none;
}
::v-deep .el-tree-node__content {
  height: auto;
  margin-bottom: 10px;
}
::v-deep .el-cascader--medium {
  line-height: 40px;
}
::v-deep .el-input__inner {
  height: 40px;
  border-color: rgb(133, 133, 133);
}

::v-deep .el-input__inner::placeholder {
  color: #000 !important;
  font-size: 13px !important;
}
::v-deep .el-input-number__decrease {
  line-height: 38px;
}
::v-deep .el-input-number__increase {
  line-height: 38px;
}
</style>