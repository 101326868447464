<template>
  <el-container class="iot-main-container">
    <el-header class="topDiv">
      <span class="titleMain">{{ HMIName }}</span>
      <hMIToolbar :key="toolBarRefresh"></hMIToolbar>
    </el-header>
    <el-container>
      <el-aside width="240px">
        <menuDsp title="页面">
          <hMIPagebar
            :key="pageBarRefresh"
            v-on:pagechanged="pageChanged"
          ></hMIPagebar>
        </menuDsp>
        <menuDsp title="基础控件">
          <hMICtrlbar></hMICtrlbar>
        </menuDsp>
      </el-aside>
      <el-main class="iot-hmi-main">
        <hMITableData
        :key="dataTableEdtRefresh"
          :visible="dataTableVisible"
          v-on:paramsDataChanged="paramsDataChanged"
        />
        <hMIDrawArea
          edit="true"
          insideMode="true"
          v-on:selectCtrlChanged="selectCtrlChanged"
        />
        <hMIImageSelector
          :key="imageSelRefresh"
          :visible="imageSelVisible"
          :name="imageSelName"
          v-on:imageChanged="imageChanged"
        />
        
        <hMITextListEditor
          :key="textLstEdtRefresh"
          :visible="textLstEdtVisible"
          v-on:textLstChanged="textLstChanged"
        />
        <hMIParamsDataEditor
          :key="paramsDataRefresh"
          :visible="paramsDataVisible"
          v-on:paramsDataChanged="paramsDataChanged"
        />
        <!-- <hMIZoombar edit="true" /> -->
        <hMIScrollbarH edit="true" />
        <hMIScrollbarV edit="true" />
      </el-main>
      <el-aside width="240px">
        <menuDsp title="页面属性" v-show="pageAttrDsp">
          <hMIPageAttrs
            :key="pageAttrRefresh"
            v-on:pagechanged="pageChanged"
            v-on:outCaller="outCaller"
          ></hMIPageAttrs>
        </menuDsp>
        <menuDsp title="系统权限" v-show="pageAttrDsp">
          <hMISysPermission
            :key="pageAttrRefresh"
            v-on:pagechanged="pageChanged"
            v-on:outCaller="outCaller"
          ></hMISysPermission>
        </menuDsp>
        <menuDsp title="控件布局" v-show="layoutDsp">
          <hMILayout :key="ctrlAttrRefresh"></hMILayout>
        </menuDsp>
        <menuDsp title="控件属性" v-show="ctrlAttrDsp">
          <hMIControlAttrs
            :key="ctrlAttrRefresh"
            v-on:outCaller="outCaller"
          ></hMIControlAttrs>
        </menuDsp>
        <menuDsp title="控件位置" v-show="ctrlAttrDsp">
          <hMIPosAttrs :key="ctrlAttrRefresh"></hMIPosAttrs>
        </menuDsp>
      </el-aside>
    </el-container>
  </el-container>
</template>
<script>
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIInterface from "../util/draw/HMIInterface.js";
import hMIToolbar from "./HMIToolbar";
import menuDsp from "./MenuDsp";
import hMIPagebar from "./HMIPagebar";
import hMICtrlbar from "./HMICtrlbar";
import hMIPageAttrs from "./HMIPageAttrs";
import hMISysPermission from "./HMISysPermission";
import hMILayout from "./HMILayout";
import hMIControlAttrs from "./HMIControlAttrs";
import hMIPosAttrs from "./HMIPosAttrs";
import hMIDrawArea from "./HMIDrawArea.vue";
import hMIImageSelector from "./HMIImageSelector.vue";
import hMITextListEditor from "./HMITextListEditor.vue";
import hMIParamsDataEditor from "./HMIParamsDataEditor.vue";
// import hMIZoombar from "./HMIZoombar.vue";
import hMIScrollbarH from "./HMIScrollbarH.vue";
import hMIScrollbarV from "./HMIScrollbarV.vue";
import hMITableData from "./HMITableData.vue";
import "@/assets/css/main.css";

let that;
export default {
  name: "Configuration",

  components: {
    hMIToolbar,
    menuDsp,
    hMIPagebar,
    hMICtrlbar,
    hMIPageAttrs,
    hMISysPermission,
    hMILayout,
    hMIControlAttrs,
    hMIPosAttrs,
    
    hMIDrawArea,
    hMIImageSelector,
    hMITextListEditor,
    hMIParamsDataEditor,
    // hMIZoombar,
    hMIScrollbarH,
    hMIScrollbarV,
    hMITableData,
  },
  // 创建
  created: function () {
    let sToken = this.$route.params.token;
    let sTokenId = this.$route.params.tokenId;
    let vplanId = this.$route.params.planId;
    let vplatType = this.$route.params.platType;
  
    this.$store.commit("setPId", vplanId);
    this.$store.commit("setToken", sToken);
    this.$store.commit("setTokenId", sTokenId);
    this.$store.commit("setPlatType", vplatType);
    this.$store.commit("setIsEdit", true);
    // 开始登录
    HMIInterface.loginSuccess = this.loadSuccess;
    HMIInterface.loginErr = this.loadErr;
    // 设置planId
    HMIInterface.planID = vplanId;
    // 登录
    HMIInterface.loginDirect(sToken);
    this.HMIName = window.localStorage.getItem("HMIName");
  },
  // 数据
  data() {
    return {
      // 工具栏刷新
      toolBarRefresh: false,
      // 页面栏刷新
      pageBarRefresh: false,
      // 页面属性刷新
      pageAttrRefresh: false,
      // 页面属性
      pageAttrDsp: true,

      // 控件属性刷新
      ctrlAttrRefresh: false,
      // 控件选中数量
      selectedCtrlsCnt: 0,
      // 控件属性
      ctrlAttrDsp: false,

      // 控件布局
      layoutDsp: false,

      // 图片选择器刷新
      imageSelRefresh: 0x10,
      // 图片选择器显示
      imageSelVisible: false,
      // 图片选择器对应属性名称
      imageSelName: "",

      // 文数对应编辑器刷新
      textLstEdtRefresh: 0x20,
      // 文数对应编辑器显示
      textLstEdtVisible: false,

      // 数据编辑器刷新
      paramsDataRefresh: 0x30,
      // 数据编辑器显示
      paramsDataVisible: false,

      // 复制控件数据
      copyControlsCnt: 0x00,
      HMIName: "物联网云组态",

      //数据表格显示
      dataTableEdtRefresh:0x40,
      dataTableVisible:false,
    };
  },
  methods: {
    // lastStep() {
    //   that.$emit("getStep", {
    //     step: 3,
    //     planId: that.currentPlanId,
    //   });
    // },
    // finish() {
    //   that.$api.plan.generateConfigFile(that.currentPlanId).then((response) => {
    //     if (response.code == 200) {
    //       location.reload();
    //       that.$emit("finish", false);
    //     }
    //   });
    // },
    close() {
      location.reload();
      that.$emit("finish", false);
    },
    // 刷新登录状态
    loadSuccess: function (vPrjData) {
      // 加载
      HMIPrj.vHMIPrjFunc.loadProject(vPrjData);
      // 刷新页面菜单
      this.pageBarRefresh = !this.pageBarRefresh;
      this.pageAttrRefresh = !this.pageAttrRefresh;
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;

      // 是否显示
      this.loginVisible = false;
      // 登录画面
      this.loginRefresh = this.loginRefresh ^ 0x01;
    },
    // 刷新失败状态
    loadErr: function () {
      // 是否显示
      this.loginVisible = false;
      // 登录画面
      this.loginRefresh = this.loginRefresh ^ 0x01;
    },
    // 获取标题
    getTitle: function () {
      var vPrj = null;
      // 检查项目是否存在
      if (HMIPrj.vHMIPrj) {
        // 获取当前项目
        vPrj = HMIPrj.vHMIPrj;
        // 检查项目是否有效
        if (vPrj) {
          // 获取页面名
          return vPrj.allPg[vPrj.curPg].name;
        }
      }
      // 错误
      return "";
    },
    // 页面属性改变
    pageChanged: function () {
      // 页面属性刷新
      this.pageBarRefresh = !this.pageBarRefresh;
      this.pageAttrRefresh = !this.pageAttrRefresh;
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;

      // 页面属性改变
      HMIPrj.vHMIPrjFunc.setProjectModified();
    },
    // 控件属性改变
    ctrlChanged: function () {
      // 暂时不做处理
    },
    // 外部调用设置选择框
    outCaller: function (type, name) {
      // 检查回调属性类型
      if (type === HG.AttrType.image) {
        //
        // 启动图片选择器对话框
        //
        // 图片选择器显示
        this.imageSelVisible = true;
        // 图片选择器刷新
        this.imageSelRefresh = this.imageSelRefresh ^ 0x01;
        // 图片选择器对应属性名称
        this.imageSelName = name;
      }
      // 检查回调属性类型
      if (type === HG.AttrType.textLstData) {
        //
        // 启动文数对应编辑器对话框
        //
        // 文数对应编辑器显示
        this.textLstEdtVisible = true;
        // 文数对应编辑器刷新
        this.textLstEdtRefresh = this.textLstEdtRefresh ^ 0x01;
      }
      // 检查回调属性类型
      if (type === HG.AttrType.paramsData) {
        //
        // 启动曲线数据编辑器对话框
        //
        // 曲线数据编辑器显示
        this.paramsDataVisible = true;
        // 曲线数据编辑器刷新
        this.paramsDataRefresh = this.paramsDataRefresh ^ 0x01;
      }
      // 检查回调属性类型
      if (type === HG.AttrType.dataTable) {
        //
        // 启动数据表格编辑器对话框
        //
        // 表格编辑器显示
       
        this.dataTableVisible = true;
        this.dataTableEdtRefresh=this.dataTableEdtRefresh ^ 0x01;
        // 表格编辑器刷新
        // this.paramsDataRefresh = this.paramsDataRefresh ^ 0x01;
      }
    },
    // 图片属性改变
    imageChanged: function () {
      // 页面属性刷新
      this.pageAttrRefresh = !this.pageAttrRefresh;
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 文本列表属性改变
    textLstChanged: function () {
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 数据改变刷新
    paramsDataChanged: function () {
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 选中控件数量改变
    selectCtrlChanged: function (selectedCnt, refreshDraw, drawStatus) {
      // 工具栏是否刷新
      let vToolbarRefresh = false;

      // 检查选中控件数量是否改变
      if (this.selectedCtrlsCnt != selectedCnt) {
        // 刷新工具栏
        vToolbarRefresh = true;
        // 设置选中控件数
        this.selectedCtrlsCnt = selectedCnt;
        // 检查选中控件数量
        if (0 == selectedCnt) {
          // 页面属性显示
          this.pageAttrDsp = true;
          // 控件布局
          this.layoutDsp = false;
        } else {
          // 页面属性隐藏
          this.pageAttrDsp = false;
          // 控件布局
          this.layoutDsp = true;
        }
        // 检查选中控件数量
        if (1 == selectedCnt) {
          // 控件属性显示
          this.ctrlAttrDsp = true;
        } else {
          // 控件属性隐藏
          this.ctrlAttrDsp = false;
        }
        // 控件属性刷新
        this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
      } else {
        // 检查选中控件数量
        if (1 == selectedCnt) {
          // 检查是否刷新绘制
          if (refreshDraw) {
            // 控件属性刷新
            this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
          }
        }
      }

      // 复制控件数据
      // 检查是否可以复制
      if (this.copyControlsCnt != HG.$Set.allCopyData.count) {
        // 复制数量
        this.copyControlsCnt = HG.$Set.allCopyData.count;
        // 刷新工具栏
        vToolbarRefresh = true;
      }

      // 检查页面是否改变
      if (drawStatus.pageChanged) {
        // 刷新工具栏
        vToolbarRefresh = true;
      }

      // 是否刷新工具栏
      if (vToolbarRefresh) {
        // 工具栏刷新
        this.toolBarRefresh = !this.toolBarRefresh;
      }
    },
  },
  beforeMount() {
    that = this;
    that.$store.commit("setPermissionArr",window.localStorage.getItem("permissions"));
  },
  mounted() {},
};
</script>

<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.iot-main-container {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #c4c4c4;
}
.plan-but {
  position: fixed;
  bottom: 10px;
  width: 100%;
  height: 35px !important;
  text-align: center;
}
.topDiv {
  width: 100%;
  min-width: 800px;
  height: 30px;
  border-bottom: 1px solid #eee;
  background-color: #f5f7fa;
}
.iot-hmi-main {
  margin: 0 !important;
  padding: 0 !important;
}

/* .attrName {
  width: 92px;
  min-height: 24px;
  padding-left: 18px;
  padding-top: 6px;
  background-color: #eef6ff;
  border-bottom: 1px solid #d5d5d5;
  float: left;
} */
</style>
