<template>
  <el-dialog
    title="图片选择"
    top="60px"
    :modal="false"
    :visible.sync="dlgVisible"
    width="65%"
  >
    <div id="prj-image-selector" class="imgMaxDiv" style="height:485px;">
  <el-tabs tab-position="left" style="height:485px" v-model="activeNames">
  <el-tab-pane v-for="item in gEchoData.imageList" :key="item.imgTypeId" :label="item.imgTypeName" :name="''+item.imgTypeId" >
    <div style="height:485px;overflow-y: auto;" v-if="activeNames==item.imgTypeId">
      <div class="imgDetailDiv">
        <el-card
          shadow="hover"
          :body-style="{ padding: '0px' }"
          style="text-align: center; position: relative;background-color: #ddd;"
        >
          <el-image
            style="height: 80px"
            :src="constant.SYSTEM_IMG + 'NO-IMG.jpg'"
            class="imageDetail"
            lazy
          >
          </el-image>
          <span>
            <el-radio v-model="selected" :label="null"> 无背景 </el-radio>
          </span>
        </el-card>
      </div>
       <div
        class="imgDetailDiv"
        v-for="(image, idx) in item.imgNames"
        :key="idx"
      >
        <div class="del" v-if="$store.state.permissionArr.indexOf('configurationFile:delete') != -1">
           <el-button type="danger" @click="deImg(image)" size="mini" icon="el-icon-delete" circle></el-button>
        </div>
        <el-card
          shadow="hover"
          :body-style="{ padding: '0px' }"
          style="text-align: center; position: relative;background-color: #ddd;"
        >
          <el-image style="height: 80px" :src="image" class="imageDetail" lazy>
          </el-image>
          <span>
            <el-radio v-model="selected" :label="image" :value="image">
              选择当前图片
            </el-radio>
          </span>
          <el-link
            style="position: absolute; top: 0px; right: 0px"
            :underline="false"
            type="danger"
            v-show="
              $store.state.permissionArr.indexOf('configurationFile:delete') >
              -1
            "
            @click="
              deImg(
                image,
                constant.OSS_URL + constant.CONFIGURATION_IMG + image
              )
            "
            ><i class="el-icon-delete"></i
          ></el-link>
        </el-card>
      </div>
    </div>
   
  </el-tab-pane>
      </el-tabs>

      <!--  -->

     
    </div>
    <span slot="footer" class="dialog-footer">
     <input type="file" id="files" ref="files" multiple @change="parameterImport" style="display:none">
      <!-- <span id="spanImgUploading" class="loadingSpan"> 上传中，请等待... </span>
      <input
        id="fileImageUpload"
        v-on:change="selectedImage"
        type="file"
        accept="image/*"
        style="display: none"
      /> -->
        <el-button
        size="small"
        type="primary"

        class="el-icon-plus iot-create-btn"
        v-show="activeNames==1||userId==1"
        @click="file()"
        >批量上传</el-button
      >
      <!-- <el-button @click="uploadImages()" size="mini">图片上传</el-button> -->
      <el-button type="primary" @click="submit()" size="mini">确 定</el-button>
      <el-button @click="dlgVisible = false" size="mini">取 消</el-button>
      
    </span>
  </el-dialog>
</template>
<style scoped>
.imgDetailDiv{
  position: relative;
}
  .del{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
    ::v-deep .el-tabs__nav-next{
    display: none;
  }
  ::v-deep .el-tabs__nav-scroll{
    overflow: scroll;
   
  }
  ::v-deep .el-tabs__nav-prev{
     display: none;
  }
  ::v-deep .el-tabs__nav div{
    text-align: left !important;
  }
   ::v-deep .el-tabs__nav-wrap{
    padding: 0 !important;
  }

</style>


<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIInterface from "../util/draw/HMIInterface.js";
import constant from "../util/constant";
import { baseUrl } from "../util/global";
import gEchoData from "../util/draw/HMIEchoData";

// 当前组件属性
export default {
  // 属性
  props: ["visible"],
  // 数据
  data: function () {
    return {
      userId:"",
      tabNames:"",
      activeNames:"",
      gEchoData: gEchoData,
      baseUrl: baseUrl,
      constant: constant,
      // 对话框key
      keyDlgImgSelector: 0,
      // 对话框是否显示
      dlgVisible: this.visible,
      // 选中的选项
      selected: null,
      // 上传图片名称
      uploadImageName: "",
      // 所有的图片
      allImages: [],

    };
  },
  // 创建
  created: function () {
      this.userId=this.$route.query.uId;
    // 对话框初始化
    this.initDlg();
   if (this.visible) {
      this.activeNames=''+gEchoData.imageList[0].imgTypeId;
   }
  },
  // 函数
  methods: {
     handleClick(tab, event) {
      this.tabNames=tab.label;
      },


    file(){
      this.$refs.files.click();
    },


  // 发送图片
    parameterImport(e){  
       let form=new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
         let file=e.target.files[i];  
      if (file.size>819200) {
       this.$message({
           type: "warning",
            message: "图片不能超过800Kb！",
       })
     }
         form.append("files",file);
      }
    
      
      form.append("imgTypeId",this.activeNames);
      
      this.$api.configurationImgType.postImg(form).then(res=>{
        if (res.code==200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getImageList();
        }else{
           this.$message({
            type: "error",
            message: res.msg,
          });
        }
      })
    },

    // 初始化对话框
  
    initDlg: function () {
      // 获取所有的图片列表
      new Promise((r) => {
        this.getImageList();
        r();
      }).then(() => {
        this.resizeDialog;
      });
      // this.getAllImages();
      // 刷新对话框
      // setTimeout(this.resizeDialog, 100);
    },
    // 获取所有的图片列表
    getAllImages: function () {
      // 遍历图片
      for (let idx = 0; idx < HG.$Set.allImgsData.length; idx++) {
        // 所有的图片
        this.allImages.push(HG.$Set.allImgsData[idx]);
      }
    },
    // 改变对话框尺寸
    resizeDialog: function () {
      // 控件
      let vSelector = document.getElementById("prj-image-selector");
      // 检查是否有效
      if (vSelector) {
        // 初始化时设置对话框的高度
        vSelector.style.Height = (window.innerHeight - 290).toString() + "px";
        vSelector.style.maxHeight = vSelector.style.Height;
      } else {
        // 刷新绘制
        requestAnimationFrame(this.resizeDialog);
      }
    },
    // 转换无效的图片
    convertValidImg: function (url) {
      // 检查是否为空
      if (url === "") return HG.$Set.noImgsData.url;
      // 直接返回
      return url;
    },
    // 转换无效的图片名
    convertValidImgSrc: function (src) {
      // 检查是否为空
      if (src === "") return HG.$Set.noImgsData.src;
      // 直接返回
      return src;
    },
    // 获取选中的图片
    // getSelectedImage: function () {
    //   // 检查选中
     
    //   if (this.selected == null) return "";
    //   // 获取选中图片的URL
    //   for (let idx = 0; idx < HG.$Set.allImgsData.length; idx++) {
    //     if (idx === this.selected) {
    //       // 返回图片SRC
    //       return HG.$Set.allImgsData[idx].src;
    //     }
    //   }

    //   return "";
    // },
    // 确定
    submit: function () {
      this.$emit('putImgs',this.selected)
      // 设置当前属性的图片
      // let page = HMIPrj.vHMIPrj.allPg[HMIPrj.vHMIPrj.curPg];
      // let ctrl = null;
      // let idx = 0;
      // // let selectedSrc = this.getSelectedImage();
      // // 检查页面是否有效
      // if (page) {
      //   // 检查是否选中控件-
      //   for (idx = 0; idx < page.ctrls.length; idx++) {
      //     // 获取选中控件
      //     if (page.ctrls[idx].selected) {
      //       // 获取控件
      //       ctrl = page.ctrls[idx];
      //       break;
      //     }
      //   }

      //   // 检查控件是否存在
      //   if (ctrl) {
      //     // 遍历key
      //     for (var key in ctrl) {
      //       // 检查键值
      //       if (key === this.name) {
      //         // 获取值
      //         ctrl[key] = this.selected;
      //         break;
      //       }
      //     }
      //   } else {
      //     // 设置页面的属性
      //     page.bgImage = this.selected;
      //   }
      // }
      // // 页面属性改变
      // this.$emit("imageChanged");
      // 隐藏对话框
      this.dlgVisible = false;
    },
    // 图片文件上传
    uploadImages: function () {
      // 控件
      let vfileImageUpload = document.getElementById("fileImageUpload");
      // 上传
      vfileImageUpload.click();
    },
    // 图片文件选择
    selectedImage: function () {
      // 控件
      let vfileImageUpload = document.getElementById("fileImageUpload");
      // loading 控件
      let vloading = document.getElementById("spanImgUploading");

      // 上传图片名称
      (this.uploadImageName = vfileImageUpload.files[0].name),
        // 保存文件
        HMIInterface.saveImage(
          vfileImageUpload.files[0],
          this.saveImageOK,
          this.saveImageErr
        );

      // 显示
      vloading.style.display = "inherit";
    },
    // 图片文件保存成功
    saveImageOK: function () {
      // 读取图片文件
      HMIInterface.loadImage(
        this.uploadImageName,
        this.loadImageOK,
        this.saveImageErr
      );
    },
    // 图片文件读取成功
    loadImageOK: function (vServeUrl) {
      // loading 控件
      let vloading = document.getElementById("spanImgUploading");
      // 隐藏
      vloading.style.display = "none";

      // 项目中的图片
      HMIPrj.vHMIPrj.allImgs.push({
        idx: HMIPrj.vHMIPrj.allImgs.length,
        name: this.uploadImageName,
        url: vServeUrl,
      });

      // 全局工程中增加
      HG.$Set.allImgsData.push({
        idx: HG.$Set.allImgsData.length,
        src: this.uploadImageName,
        url: vServeUrl,
        img: null,
      });

      // 所有的图片
      this.allImages.push(HG.$Set.allImgsData[HG.$Set.allImgsData.length - 1]);
    },
    // 图片文件保存失败
    saveImageErr: function () {
      // loading 控件
      let vloading = document.getElementById("spanImgUploading");
      // 隐藏
      vloading.style.display = "none";

      // 消息
      this.$alert("图片上传错误！", "错误", {
        confirmButtonText: "确定",
        type: "warning",
      });
    },
    onSuccess: function () {
      //刷新列表
      this.getImageList();
    },
    getImageList: function () {
      HMIInterface.configurationImage();
    },
    deImg: function (name) {
      HMIInterface.delImage(name);
    },
  },
};
</script>
